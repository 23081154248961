<template>
    <header :class="['header', headerScroll ? 'active_header  animate__animated' : '']">
        <div class="container">
            <div class="nav">
                <div class="logo" @click.prevent="go('')">
                    <img :src="imgUrl('/img/hopeAi.svg')"
                        alt="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu"
                        title="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu" />
                </div>
                <ClientOnly>
                    <div class="right_action">
                        <div>
                            <n-dropdown class="solution_dropdown" trigger="hover" :options="options"
                                @select="solutionSelect" size="large">
                                Solutions
                            </n-dropdown>
                        </div>
                        <div class="menu_item" @click="go('company')">
                            Company
                        </div>
                        <div class="menu_item" @click="go('research')">
                            Research
                        </div>
                        <div @click="opens" class="signup">
                            Request a Demo
                        </div>
                    </div>
                    <div class="web_right_action">
                        <n-icon size="30" color="#fff" @click.prevent="openMenu = true">
                            <Menu />
                        </n-icon>
                        <template v-if="openMenu">
                            <div class="open_modal">
                                <div class=" modal_box">
                                    <div class="menu_header">
                                        <div class="menu_logo" @click.prevent="go('')">
                                            <img :src="imgUrl('/img/hopeAi.svg')"
                                                alt="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu"
                                                title="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu" />
                                        </div>
                                        <n-icon size="30" color="#fff" @click.prevent="openMenu = false">
                                            <Close />
                                        </n-icon>
                                    </div>
                                    <div class="menu_list">
                                        <p>
                                            <n-collapse class="list_collapse" :default-expanded-names="['2']">
                                                <template #header-extra>
                                                </template>
                                                <n-collapse-item title="Solutions" name="2">
                                                    <div class="coll_item" @click.prevent="go('PUREEvidence')">PURE
                                                        Evidence
                                                    </div>
                                                    <div class="coll_item" @click.prevent="go('synthIPD')">SynthIPD
                                                    </div>
                                                </n-collapse-item>
                                            </n-collapse>

                                        </p>
                                        <p @click.prevent="go('company')">
                                            Company
                                        </p>
                                        <p @click.prevent="go('research')">
                                            Research
                                        </p>
                                        <p @click.prevent="opens" class="signup">
                                            Request a Demo
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div>
                        </div>

                    </div>
                </ClientOnly>
            </div>
        </div>
        <n-modal v-model:show="openModal" :auto-focus="false" :class="[openMenu ? 'mobile_modal' : '']">
            <n-card style="width: 600px" title="Request a Demo" :bordered="false" size="huge" role="dialog"
                :aria-modal="true">
                <template #header-extra>
                    <n-icon size="20" @click.prevent="close" style="cursor: pointer;">
                        <Close />
                    </n-icon>
                </template>

                <div class="request_modal">
                    <p class="request_notice">Enter your contact details and we'll be in touch</p>
                    <n-form ref="formRef" :model="model" :rules="rules">
                        <n-form-item path="full_name" label="Full Name">
                            <n-input v-model:value="model.full_name" placeholder="" />
                        </n-form-item>
                        <n-form-item path="email" label="Email">
                            <n-input v-model:value="model.email" placeholder="" @keydown.enter.prevent />
                        </n-form-item>
                        <n-form-item path="title" label="Title">
                            <n-input v-model:value="model.title" placeholder="" @keydown.enter.prevent />
                        </n-form-item>
                        <n-form-item path="company" label="Company">
                            <n-input v-model:value="model.company" placeholder="" @keydown.enter.prevent />
                        </n-form-item>
                        <n-form-item path="request" label="">
                            <div class="request_form_item">
                                <p>What are you most interested in learning about? </p>
                                <p>
                                    How can we help? Share more here.</p>
                                <n-input v-model:value="model.request" type="textarea" :autosize="{
                                    minRows: 3,
                                    maxRows: 3,
                                }" placeholder="" @keydown.enter.prevent />
                            </div>
                        </n-form-item>
                        <n-row :gutter="[0, 24]">
                            <n-col :span="24">
                                <div style="display: flex; justify-content: center">
                                    <template v-if="openModal">
                                        <vue-turnstile :site-key="cloud_key" v-model="token" theme="auto" />
                                    </template>
                                    <n-button class="request_submit" :disabled="token.length === 0" round type="primary"
                                        size="large" :loading="loading" @click="handleValidateButtonClick">
                                        Submit
                                    </n-button>
                                </div>
                            </n-col>
                        </n-row>
                    </n-form>
                </div>

            </n-card>
        </n-modal>
    </header>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
// import { NButton, NInput, NCol, NRow, NCard, NIcon, useMessage, NCollapse, NCollapseItem } from 'naive-ui';
import { useMessage } from 'naive-ui';
import VueTurnstile from 'vue-turnstile';
import { getNewLeadsService } from "@/api/index";
import { Close, Menu } from '@vicons/ionicons5';

const emit = defineEmits(['change']);
const openModal = ref(false);
const opens = () => {
    openModal.value = true;
    model.value = {
        full_name: '',
        email: '',
        title: '',
        company: '',
        request: ''
    }
}

const openMenu = ref(false);
const loading = ref(false);
const config = useRuntimeConfig();
const router = useRouter();
const route = useRoute();
const formRef = ref<any>(null);
const cloud_key = process.env.CLOUD_KEY || "";
const token = ref<string>('');
const model = ref<any>({
    full_name: '',
    email: '',
    title: '',
    company: '',
    request: ''
})
const rules = ref<any>({
    full_name: [{
        required: true,
        message: 'please enter your full name',
        trigger: 'blur'
    }],
    email: [{
        required: true,
        message: ' please enter your email',
        trigger: 'blur'
    },
    {
        type: 'email',
        message: 'please enter a valid email',
        trigger: 'blur'
    }],
    title: [{
        required: true,
        message: 'please enter your title',
        trigger: 'blur'
    }],
    company: [{
        required: true,
        message: 'please enter your company',
        trigger: 'blur'
    }],
})

const message = useMessage();
const imgUrl = (url: string) => {
    return config.app.cdnURL + url;
}
const headerScroll = ref(false);
const options = ref([
    {
        label: 'PURE Evidence',
        key: 'PUREEvidence'
    },
    {
        label: 'SynthIPD',
        key: 'SynthIPD'
    },
]);

const solutionSelect = (option: string) => {
    router.push({ path: `/${option}` });
    const hopeaiHome = document.getElementsByClassName('hopeai_home')[0];
    // 回到顶部
    if (hopeaiHome) {
        hopeaiHome.scrollTop = 0;
    }
}

const go = (name: string) => {
    router.push({ path: `/${name}` });
    const hopeaiHome = document.getElementsByClassName('hopeai_home')[0];
    // 回到顶部
    if (hopeaiHome) {
        hopeaiHome.scrollTop = 0;
    }
    if (route.name === 'index' || route.name === name) {
        openMenu.value = false;
    }


}


const close = () => {
    openModal.value = false;
}
const handleValidateButtonClick = () => {

    formRef.value?.validate((errors: any) => {
        if (!errors) {
            loading.value = true;
            getNewLeadsService(model.value).then((res: any) => {
                if (res) {
                    message.success('Thank you for your interest in our product! We will be in touch shortly.', { duration: 8000 });
                    loading.value = false;
                    openModal.value = false;
                }
            }).catch(err => {
                console.log(err)
                loading.value = false;
                message.error('Request failed, please try again later');
            })
        }
    })
}
watch(() => token.value, (val) => {
    if (val && val.length > 0) {
        localStorage.setItem('CToken', val)
    }
}, { immediate: true })

watch(() => openMenu.value, (val) => {
    if (val) {
        emit('change', true);
        document.body.classList.add('no-scroll');
    } else {
        emit('change', false);
        document.body.classList.remove('no-scroll');
    }
})

onMounted(() => {
    // hopeai_home 监听滚动 
    const hopeaiHome = document.getElementsByClassName('hopeai_home')[0]
    if (hopeaiHome) {
        hopeaiHome.addEventListener('scroll', () => {
            if (hopeaiHome.scrollTop > 0) {
                headerScroll.value = true;
            } else {
                headerScroll.value = false;
            }
        })
    }

})
</script>
<style lang="scss">
.no-scroll {
    height: 100vh;
    overflow: hidden;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

.open_modal {
    transition: all, transform 400ms;
    transform: translateY(0px) translateX(0px);
    position: fixed;
    inset: 0% 0% auto;
    height: 100svh;
    width: 100vw;
    left: 0;
    background: #000;
    z-index: 1000;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

.modal_box {
    width: 100%;
    height: 80vh;
    overflow: hidden;
}

.home_wrap {
    width: 100%;
    height: auto;
    /* height: 100%; */
    overflow-y: auto;
    overflow-x: hidden;
    // cursor: pointer;

    &.mobileModal {
        height: 100vh;
        overflow: hidden;
        background: #0D121F;
    }
}


.active_header {
    background: rgba(13, 18, 31, 0.45);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
}




.menu_modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #0D121F;
    height: 100vh;
    overflow: hidden;

    .menu_content {
        position: relative;
        padding: 0 20px;
        height: 100vh;
        overflow: hidden;
        background: #0D121F;
        -webkit-overflow-scrolling: touch;




    }
}



.demo_div {
    min-height: 405px;
    background: #f5f6f9;
    width: 100%;
    border-radius: 8px;
}

.demo_divs {
    min-height: 405px;
    background: #f5f6f9;
    width: 100%;
    border-radius: 8px;
}

.logo {
    width: 140px;
    display: flex;
    align-items: center;
    // cursor: pointer;

    img {
        position: relative;
        z-index: 100;
        cursor: pointer;
        width: 100%;
        // cursor: pointer;
        pointer-events: auto;
    }
}

.solution_dropdown .n-dropdown-option .n-dropdown-option-body .n-dropdown-option-body__label {
    font-weight: 600;
}

.solution_dropdown .n-dropdown-option .n-dropdown-option-body {
    height: 56px;
    line-height: 56px;
}



.right_action {
    display: flex;
    align-items: center;
    gap: 40px;
    color: #fff;
    pointer-events: auto;

    div {
        font-size: 18px;
        font-weight: 600;
        height: 48px;
        line-height: 48px;
        // cursor: pointer;
        pointer-events: auto;
        position: relative;
        z-index: 100;
        cursor: pointer;

        span {
            font-size: 18px;
            font-weight: 600;
            // cursor: pointer;
        }

        &::after {
            content: "";
            position: absolute;

        }


        &.menu_item {
            &:hover {
                &::after {
                    content: "";
                    position: absolute;
                    left: 12px;
                    right: 12px;
                    height: 4px;
                    /* width: 100%; */
                    background: #7A47B9FF;
                    bottom: 0;
                    border-radius: 8px;

                }
            }
        }

    }

    .btn {
        user-select: none;
        border: 0;
        line-height: 48px;
        font-size: 16px;
        // cursor: pointer;
        text-align: center;
    }

    .btn.login {
        background: none;
        color: #fff;
        /* padding: 0 24px; */
        border-radius: 24px;
        flex: none;
        order: 1;
        flex-grow: 0;
        width: 95px;
        letter-spacing: 0.5px;

    }

    .signup {
        letter-spacing: 0.5px;
        flex: none;
        order: 1;
        flex-grow: 0;
        padding: 0 24px;
        font-weight: 600;
        font-size: 16px;
        border-radius: 99px;
        border: 1px solid #6836A8;
        background: #6939A3;

        &:hover {
            background: #7A47B9FF;
        }
    }
}

.web_right_action {
    display: none;
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 90px;
    z-index: 1001;
    // cursor: pointer !important;

    .container {
        height: 100%;
        // cursor: pointer;
    }
}

.nav {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // cursor: pointer;
}

.solution_dropdown {
    margin-top: 14px !important;
}


.container {
    max-width: 1240px;
    height: 100%;
    margin: 0 auto;
}

.request_modal {
    margin: 0 auto;

    .request_notice {
        color: var(--mastergo-com-black-50, rgba(0, 0, 0, 0.50));
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 32px;
        margin-top: -24px;
    }

    .n-form-item-label__text {
        color: #23202A;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }
}

.request_submit {
    width: 150px;
    color: #fff;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.request_form_item {
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
        color: var(--portal-unlearn-ai-baltic-sea, #23202A);
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    .n-input {
        margin-top: 10px;
    }
}

.header_mobile_modal {
    margin: 0;
    width: 100vh !important;
    height: 100vh !important;
    border-radius: 0;
    background: #0D121F !important;

    .n-card-header {
        width: 100%;
        padding: 0;
        display: block;
    }


}

.menu_header {
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0 20px;
    background: #000;
    position: relative;
    z-index: 1001;

    .menu_logo {
        width: 140px;
        display: flex;
        align-items: center;


        img {
            width: 100%;
        }
    }
}

.menu_list {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
    padding: 0 20px;
    animation: slideDown 0.2s ease-out;

    p {
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
    }

    .signup {
        border-radius: 99px;
        letter-spacing: 0.5px;
        flex: none;
        order: 1;
        flex-grow: 0;
        padding: 0 30px;
        line-height: 48px;
        margin-top: 12px;
        font-size: 16px;
        border-radius: 99px;
        border: 1px solid #6836A8;
        background: #6939A3;

        &:hover {
            background: #7A47B9FF;
        }
    }

    .coll_item {
        font-size: 22px;
        font-weight: 500;
        line-height: 48px;
        color: #bbb;
        padding-left: 20px;

        &:last-child {
            padding-bottom: 20px;
        }
    }
}

.list_collapse {
    .n-collapse-item__header-main {
        color: #fff !important;
        font-size: 32px;
        font-weight: 500 !important;
        line-height: 48px;
    }

    .n-collapse-item-arrow {
        display: none !important;
    }
}

@media (max-width: 576px) {

    .container {
        margin: 0 20px;
    }

    .demo_div {
        width: 100%;
    }

    .right_action {
        display: none;
    }

    .web_right_action {
        display: block;
    }

    .request_modal {
        height: 80vh;
        overflow: auto;

        .request_notice {
            margin-top: 0;
        }

    }

    .mobile_modal {
        margin: 0;
        width: 100vh !important;
        height: 100vh !important;
        border-radius: 0;
    }


}

@media (min-width: 577px) and (max-width: 768px) {
    .container {
        margin: 0 40px;
    }

    .right_action {
        display: none;
    }

    .web_right_action {
        display: block;
    }

    .menu_modal .menu_content .menu_header .menu_logo {
        width: 140px;
    }
}

@media (min-width: 769px) and (max-width: 1365px) {
    .container {
        margin: 0 80px;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -15px, 0);
        --webkit-transform: translate3d(0, -15px, 0);
    }

    to {
        opacity: 1;
        transform: none;
        --webkit-transform: none;
    }
}

.fadeInDown {
    animation-name: fadeInDown;
    --webkit-animation-name: fadeInDown;
    animation-duration: 1.5s;
}
</style>